import { classNames } from '@/lib/utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { i18nRoute } from 'routes';

export default function CompanyLink({
	className,
	children,
	company,
	useNativeNavigation = false,
}) {
	const router = useRouter();
	const { i18n } = useTranslation('common');

	const href =
		company.transportType && company.transportType.toLowerCase() === 'train'
			? `/${router.locale}/companies/${company.id}`
			: i18nRoute(`/companies/[companyId]`, i18n.language, {
					companyId: company.id,
			  });

	const linkProps = {
		className: classNames('focus:outline-none', className),
		href,
		title: company.name,
	};

	return useNativeNavigation ? (
		<a {...linkProps}>{children ? children : company.name}</a>
	) : (
		<Link {...linkProps}>{children ? children : company.name}</Link>
	);
}
