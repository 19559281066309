import { fetcher } from '@/lib/utils';
import { Combobox, Dialog } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRef, useState } from 'react';
import useSWR from 'swr';

const CountryFlag = dynamic(() => import('@/components/ui/CountryFlag'), {
	ssr: false,
});

export function Autocomplete({
	Icon,
	locale,
	label,
	initialValue = null,
	initialOptions,
	placeholder,
	onSelect,
	compact,
	region,
}) {
	const [location, setLocation] = useState(initialValue);
	const [query, setQuery] = useState('');

	const inputRef = useRef(null);

	const apiParams = [
		`term=${query}`,
		`type=CITY`,
		`size=10`,
		`language=${locale}`,
	];
	if (region) {
		apiParams.push(`countryCode=${region}`);
	}

	const { data: suggestions } = useSWR(
		query ? `/api/suggestions?${apiParams.join('&')}` : null,
		fetcher,
		{
			fallbackData: initialOptions?.data,
		}
	);

	const changeHandler = (suggestion) => {
		setLocation(suggestion);
		onSelect(suggestion);
	};

	const resetHandler = () => {
		setQuery('');
		setLocation(null);
	};

	return (
		<Combobox
			as="div"
			className="w-full"
			onChange={changeHandler}
			nullable={true}
			value={location}
		>
			{({ open }) =>
				open && window?.outerWidth < 1024 ? (
					<Dialog
						open={open}
						as="div"
						className="relative z-[2147483648]"
						onClose={resetHandler}
						initialFocus={inputRef}
					>
						<div className="fixed inset-0 overflow-y-auto">
							<div className="flex min-h-full justify-center">
								<Dialog.Panel className="w-full bg-white">
									<div className="relative pt-12">
										<div className="fixed inset-x-0 top-0 z-10 flex items-center bg-white shadow">
											<button className="p-2" onClick={() => resetHandler()}>
												<ChevronLeftIcon
													aria-hidden
													className="h-8 w-8 text-gray-600"
												/>
											</button>
											<Combobox.Input
												ref={inputRef}
												autoFocus
												autoComplete="off"
												onChange={(event) => setQuery(event.target.value)}
												className="flex-1 border-0 py-3 pl-0 pr-4 placeholder-slate-400 focus:ring-0"
												placeholder={placeholder}
											/>
										</div>
										{suggestions && suggestions.length > 0 && (
											<Combobox.Options className="mt-1 w-full overflow-auto bg-white text-base">
												{suggestions.map((suggestion) => (
													<Combobox.Option
														key={suggestion.id}
														value={suggestion}
														className={({ active }) =>
															`relative flex cursor-default select-none items-center py-2 pl-3 text-left ${
																active
																	? 'bg-slate-100 text-slate-700'
																	: 'text-slate-700'
															}`
														}
													>
														<CountryFlag
															countryCode={suggestion.countryCode}
															className="size-6 shrink-0"
														/>
														<span className="block truncate px-3">
															{suggestion.longName}
														</span>
													</Combobox.Option>
												))}
											</Combobox.Options>
										)}
									</div>
								</Dialog.Panel>
							</div>
						</div>
					</Dialog>
				) : (
					<>
						<Combobox.Label
							htmlFor={`search-form-${placeholder}-field`}
							className="sr-only"
						>
							{label}
						</Combobox.Label>
						<div className="group relative">
							<div className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-4 focus:outline-none">
								<Icon
									className={clsx(
										'h-5 w-5 text-gray-500',
										compact && 'md:h-4 md:w-4'
									)}
									aria-hidden="true"
								/>
							</div>
							<Combobox.Button className="w-full">
								<Combobox.Input
									id={`search-form-${placeholder}-field`}
									required
									type="text"
									autoComplete="off"
									displayValue={(suggestion) =>
										suggestion?.longName || suggestion?.shortName
									}
									onChange={(event) => setQuery(event.target.value)}
									className={clsx(
										'block w-full rounded-full border-0 py-3 pl-12 pr-6 text-base font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 placeholder:font-medium placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600',
										compact && 'md:py-2 md:pl-10 md:pr-4 md:text-sm'
									)}
									placeholder={placeholder}
								/>
							</Combobox.Button>
							<Combobox.Options className="absolute z-10 mt-1 max-h-[420px] w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								{suggestions?.map((suggestion) => (
									<Combobox.Option
										key={suggestion.id}
										value={suggestion}
										className={({ active }) =>
											`relative flex cursor-pointer select-none items-center gap-x-3 py-2 pl-3 pr-4 text-left lg:text-sm ${
												active
													? 'bg-indigo-50 font-bold text-gray-900'
													: 'text-gray-700'
											}`
										}
									>
										{({ selected, active }) => (
											<>
												<CountryFlag
													className={clsx(
														'shrink-0',
														compact ? 'size-4' : 'size-6'
													)}
													countryCode={suggestion.countryCode}
												/>
												<span
													className={`block truncate ${
														selected ? 'font-medium' : 'font-normal'
													}`}
												>
													{suggestion.longName}
												</span>
											</>
										)}
									</Combobox.Option>
								))}
							</Combobox.Options>
						</div>
					</>
				)
			}
		</Combobox>
	);
}
