import { useTranslation } from 'next-i18next';
import Image from 'next/image';

import CompanyLink from '@/components/navigation/CompanyLink';
import { SearchForm } from '@/components/ui/SearchForm';
import { project } from '@/lib/project';
import { StaticLogo } from '../ui/StaticLogos';

export default function Hero({ companies }) {
	const { t } = useTranslation(['home']);

	return (
		<div className="relative isolate px-4 lg:px-8">
			<div className="mx-auto max-w-4xl pt-6 text-center sm:pt-16 lg:pt-24">
				<h1 className="font-display mx-auto max-w-2xl text-3xl font-medium tracking-tight text-slate-900 md:text-5xl lg:text-7xl">
					{t(`home:sections.hero.${project.transportType}.title_1`)}
					<span className="relative inline-block whitespace-nowrap px-2 text-indigo-600">
						<svg
							aria-hidden="true"
							viewBox="0 0 418 42"
							className="absolute left-0 top-2/3 h-[0.58em] w-full fill-indigo-300/70"
							preserveAspectRatio="none"
						>
							<path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
						</svg>
						<span className="relative">
							{t(`home:sections.hero.${project.transportType}.title_highlight`)}
						</span>
					</span>
					{t(`home:sections.hero.${project.transportType}.title_2`)}
				</h1>
				<h2 className="my-6 text-lg leading-6 text-gray-600 md:mb-10 md:mt-4">
					{t(`home:sections.hero.${project.transportType}.subtitle`)}
				</h2>
				<SearchForm className={'mx-auto'} />
			</div>
			<div className="mx-auto max-w-6xl pt-6 text-center lg:pt-24">
				<h3 className="mb-6 text-lg leading-6 text-gray-600 md:mb-12 md:mt-10">
					{t(`home:sections.hero.${project.transportType}.companies_title`)}
				</h3>
				<div className="grid grid-cols-2 gap-8 md:grid-cols-6 md:gap-12 lg:grid-cols-6">
					{companies.map((company) => (
						<div
							key={`hero-${company.id}`}
							className="col-span-1 flex items-center justify-center md:col-span-2 lg:col-span-1"
						>
							<CompanyLink company={company}>
								<span className="sr-only">{company.name}</span>
								<Image
									priority
									src={StaticLogo({ id: company.id })}
									alt={company.name}
									title={company.name}
									width={640}
									height={360}
									className="mx-auto max-h-16 w-full object-contain md:mx-0 md:object-left"
								/>
							</CompanyLink>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
