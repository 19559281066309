import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { i18nRoute } from 'routes';

export default function RouteLink({
	className,
	children,
	route,
	title,
	transportType,
	useNativeNavigation = false,
}) {
	const { t, i18n } = useTranslation('common');

	if (!route.slug && route.origin && route.destination) {
		route.slug = `${route.origin.slug}-x-${route.destination.slug}`;
	}

	let routePathname = 'tickets';
	if (transportType && transportType === 'train') {
		routePathname = transportType;
	}
	const href = i18nRoute(`/${routePathname}/[routeId]`, i18n.language, {
		routeId: route.slug,
	});

	title =
		title ||
		t(
			`relatedRoutes.${
				transportType ? transportType + '_' : ''
			}tickets_from_to`,
			{
				origin: route.originName || route.origin.shortName,
				destination: route.destinationName || route.destination.shortName,
			}
		);

	const linkProps = {
		className,
		title,
		href,
	};

	const linkText = t('relatedRoutes.to', {
		origin: route.originName || route.origin.shortName,
		destination: route.destinationName || route.destination.shortName,
	});

	return useNativeNavigation ? (
		<a {...linkProps}>{children ? children : linkText}</a>
	) : (
		<Link {...linkProps}>
			{children ? (
				children
			) : (
				<>
					<span className="truncate">{linkText}</span>
				</>
			)}
		</Link>
	);
}
